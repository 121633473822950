import { toLower } from 'lodash';

import breakIcon from '../assets/icons/mixl/break-today-icon.svg';
import exerciseIcon from '../assets/icons/mixl/exercise-today-icon.svg';
import blackFlagImg from '../assets/icons/mixl/flags/black.svg';
import highFlagImg from '../assets/icons/mixl/flags/high.svg';
import lowFlagImg from '../assets/icons/mixl/flags/low.svg';
import normalFlagImg from '../assets/icons/mixl/flags/normal.svg';
import urgentFlagImg from '../assets/icons/mixl/flags/urgent.svg';
import learningIcon from '../assets/icons/mixl/learning-today-icon.svg';
import meetingIcon from '../assets/icons/mixl/meeting-today-icon.svg';
import commonProgressIcon from '../assets/icons/mixl/progress/common.svg';
import doneProgressIcon from '../assets/icons/mixl/progress/done.svg';
import openProgressIcon from '../assets/icons/mixl/progress/open.svg';
import reviewProgressIcon from '../assets/icons/mixl/progress/review.svg';
import runningProgressIcon from '../assets/icons/mixl/progress/running.svg';
import clickupIcon from '../assets/Mobile/platfoms/clickup.svg';
import jiraIcon from '../assets/Mobile/platfoms/jira.svg';
import notionIcon from '../assets/Mobile/platfoms/notion.svg';
import { TaskDayTypes, TaskTagTypes } from '../redux/mixl/types';
import { IMixlTask } from '../utils/Types';
import Request from './Request';

const { GetSecuredData } = Request();

export const fetchClickupTasks = async (token) => {
  const { data } = await GetSecuredData('mixer/get_clickup_tasks', `${token}`);
  if (!data && !data?.length > 0) {
    return [];
  }
  const tasks: IMixlTask[] = await Promise.all(
    data.map((item) => {
      const date_created = parseInt(item?.date_created ?? Date.now());
      const status = extractClickupTaskStatus(item);
      const priority = extractClickupTaskPriority(item);
      const task: IMixlTask = {
        details: item?.description ?? '',
        status,
        name: item?.name ?? '',
        isTodayTask: true,
        type: 'clickup',
        tagName: '',
        priority,
        createdAt: date_created,
        id: item?.id ?? '',
        startTimestamp: date_created
      };

      return task;
    })
  );
  return tasks;
};

export const taskTagOptionList = Object.keys(TaskTagTypes).map((e) => {
  const value = TaskTagTypes[e];
  return { value, className: value };
});

export const taskDayOptionList = Object.keys(TaskDayTypes).map((e) => {
  const value = TaskDayTypes[e];
  return { value, className: value };
});

export const getTaskDateText = (isToday: boolean) => {
  if (isToday) {
    return TaskDayTypes.today;
  }
  return TaskDayTypes.later;
};

export const progressOptionList = [
  { value: 'open', icon: openProgressIcon },
  { value: 'in progress', icon: runningProgressIcon },
  { value: 'review', icon: reviewProgressIcon },
  { value: 'done', icon: doneProgressIcon }
];

export const getTaskProgressIcon = (status: string) => {
  switch (status) {
    case 'in progress':
      return runningProgressIcon;
    case 'done':
    case 'Closed':
      return doneProgressIcon;
    case 'review':
      return reviewProgressIcon;
    case 'open':
      return openProgressIcon;
    default:
      return commonProgressIcon;
  }
};

export const getImgPriority = (priority: string) => {
  // console.log('priority===', priority);
  switch (priority) {
    case 'low':
      return lowFlagImg;
    case 'normal':
      return normalFlagImg;
    case 'high':
      return highFlagImg;
    case 'urgent':
      return urgentFlagImg;
    default:
      return blackFlagImg;
  }
};

export const getTaskTypeIcon = (type: string) => {
  switch (type) {
    case 'jira':
      return jiraIcon;
    case 'clickup':
      return clickupIcon;
    case 'notion':
      return notionIcon;
    default:
      return;
  }
};

export const getImgEvent = (type: string) => {
  // console.log('priority===', priority);
  switch (type) {
    case 'exercise':
      return exerciseIcon;
    case 'learning':
      return learningIcon;
    case 'break':
      return breakIcon;
    case 'meeting':
      return meetingIcon;
    default:
      return breakIcon;
  }
};

function extractClickupTaskStatus(item: unknown) {
  if (item?.status) {
    const status = { ...item.status };
    status['status'] = toLower(item?.status?.status || 'open');
    return status;
  }
  return { status: 'open' };
}

function extractClickupTaskPriority(item: unknown) {
  if (item?.priority) {
    const priority = { ...item.priority };
    priority['priority'] = toLower(item?.priority?.priority || 'normal');

    return priority;
  }
  return {
    color: '#6fddff',
    id: '3',
    orderindex: '3',
    priority: 'normal'
  };
}
