import { IMixlEvent, IMixlTask } from '../../utils/Types';

export const mixlActionTypes = {
  ADD_TASK_DATA_QUEUE: 'ADD_TASK_DATA_QUEUE',
  REMOVE_TASK_DATA_QUEUE: 'REMOVE_TASK_DATA_QUEUE',
  ADD_SPLIT_SESSION_DATA: 'ADD_SPLIT_SESSION_DATA',
  CLEAR_SPLIT_SESSION_DATA: 'CLEAR_SPLIT_SESSION_DATA',
  ADD_BLOCKED_SITE: 'ADD_BLOCKED_SITE',
  REMOVE_BLOCKED_SITE: 'REMOVE_BLOCKED_SITE',
  UPDATE_SESSION_ID: 'UPDATE_SESSION_ID',
  UPDATE_CURRENT_USER_INFO: 'UPDATE_CURRENT_USER_INFO',
  FETCHING_PLAYLIST: 'FETCHING_PLAYLIST',
  SET_CURRENT_MUSIC: 'SET_CURRENT_MUSIC',
  SHOW_RESTART_SESSION_MODAL: 'SHOW_RESTART_SESSION_MODAL',
  SET_TIMER_VALUE: 'SET_TIMER_VALUE',
  UPDATE_SESSION_LENGTH: 'UPDATE_SESSION_LENGTH',
  SET_BLOCK_TIME_GOAL: 'SET_BLOCK_TIME_GOAL',
  SET_STOP_START_TIMER: 'SET_STOP_START_TIMER',
  SET_CURRENT_PHASE: 'SET_CURRENT_PHASE',
  SET_FULL_CURRENT_MUSIC: 'SET_FULL_CURRENT_MUSIC',
  SET_FULL_PROGRESS_MUSIC: 'SET_FULL_PROGRESS_MUSIC',
  SHOW_WAVE_INFO_MODAL: 'SHOW_WAVE_INFO_MODAL',
  SHOW_CLICKUP_LIST_MODAL: 'SHOW_CLICKUP_LIST_MODAL',
  SHOW_APP_UPDATE_MODAL: 'SHOW_APP_UPDATE_MODAL',
  SET_CLICKUP_LIST_TASKS: 'SET_CLICKUP_LIST_TASKS',
  LOADING_CLICKUP_LIST_TASKS: 'LOADING_CLICKUP_LIST_TASKS',
  SET_SELECTED_TASK_CLICKUP: 'SET_SELECTED_TASK_CLICKUP',
  SET_MIXL_SCREEN: 'SET_MIXL_SCREEN',
  SET_MILESTONES: 'SET_MILESTONES',
  UPDATE_MUSIC_STATION: 'UPDATE_MUSIC_STATION',
  SHOW_STATIONS_LIST_MODAL: 'SHOW_STATIONS_LIST_MODAL',
  UPDATE_SESSION_STATUS: 'UPDATE_SESSION_STATUS',
  SET_MUSIC_LIST: 'SET_MUSIC_LIST',
  UPDATE_PLAYER_VOLUME: 'UPDATE_PLAYER_VOLUME',
  UPDATE_MAIN_VOLUME: 'UPDATE_MAIN_VOLUME',
  SHOW_UPDATE_VOLUME_MODAL: 'SHOW_UPDATE_VOLUME_MODAL',
  SET_BREAK_TIME: 'SET_BREAK_TIME',
  SET_SESSION_FULL_TIME: 'SET_SESSION_FULL_TIME',
  SET_BREAK_TYPE: 'SET_BREAK_TYPE',
  SET_TEAM_LIST: 'SET_TEAM_LIST',
  LOADING_TEAM_LIST: 'LOADING_TEAM_LIST',
  SHOW_BINAURAL_BEATS_MODAL: 'SHOW_BINAURAL_BEATS_MODAL',
  SET_MUSIC_STATIONS: 'SET_MUSIC_STATIONS',
  SET_AI_SESSIONS_DATA: 'SET_AI_SESSIONS_DATA',
  SET_CLEAR_TABS: 'SET_CLEAR_TABS',
  PUBLISH_TO_SLACK: 'PUBLISH_TO_SLACK',
  SET_SESSION_WARMUP_QUOTE: 'SET_SESSION_WARMUP_QUOTE',
  SET_SESSION_BREAK_QUOTE: 'SET_SESSION_BREAK_QUOTE',
  SET_SKIPPED_WARMUP: 'SET_SKIPPED_WARMUP',
  SET_REFERRALS_LIST: 'SET_REFERRALS_LIST',
  SET_END_SESSION_LOADING: 'SET_END_SESSION_LOADING',
  SET_WEBSITES_BLOCKING_TYPE: 'SET_WEBSITES_BLOCKING_TYPE',
  SET_TEAM_LEADERS: 'SET_TEAM_LEADERS',
  SET_RATING_CURRENT_SESSION: 'SET_RATING_CURRENT_SESSION',
  SHOW_TASK_DETAILS_MODAL: 'SHOW_TASK_DETAILS_MODAL',
  SET_SELECTED_TIMER_OPTION: 'SET_SELECTED_TIMER_OPTION',
  SET_SELECTED_PROGRESS_TAB: 'SET_SELECTED_PROGRESS_TAB',
  SET_SESSION_DATA: 'SET_SESSION_DATA',
  SHOW_SITE_BLOCKER_MODAL: 'SHOW_SITE_BLOCKER_MODAL',
  SET_BLOCKED_SITES: 'SET_BLOCKED_SITES',
  SHOW_ADD_BLOCK_SITE_MODAL: 'SHOW_ADD_BLOCK_SITE_MODAL',
  SET_GENERATING_BLOCKED_SITES: 'SET_GENERATING_BLOCKED_SITES',
  SET_SHOW_TRACKED_SITES_MODAL: 'SET_SHOW_TRACKED_SITES_MODAL',
  SET_BLOCK_ALL_SITES: 'SET_BLOCK_ALL_SITES',
  SET_IS_DAY_SHCEDULED: 'SET_IS_DAY_SHCEDULED',
  IS_SCHEDULING: 'IS_SCHEDULING',
  SET_SHOW_WORK_HOURS_MODAL: 'SET_SHOW_WORK_HOURS_MODAL',
  SET_SHOW_FOCUS_DETAILS_MODAL: 'SET_SHOW_FOCUS_DETAILS_MODAL',
  SET_SELECTED_USER: 'SET_SELECTED_USER',
  UPDATE_SELECTED_USER: 'UPDATE_SELECTED_USER',
  SET_FETCHING_TEAM_LIST: 'SET_FETCHING_TEAM_LIST',
  UPDATE_MIXL_CALENDAR: 'UPDATE_MIXL_CALENDAR',
  SET_SHOW_INTEGRATIONS_MODAL: 'SET_SHOW_INTEGRATIONS_MODAL',
  SET_SHOW_SLACK_MESSAGE_MODAL: 'SET_SHOW_SLACK_MESSAGE_MODAL',
  SET_CREATING_READING_LIST: 'SET_CREATING_READING_LIST',
  SET_SHOW_END_DETAILS_MODAL: 'SET_SHOW_END_DETAILS_MODAL',
  PLAYED_END_SESSION_AUDIO: 'PLAYED_END_SESSION_AUDIO',
  SET_SHOW_FOCUS_STATS_MODAL: 'SET_SHOW_FOCUS_STATS_MODAL'
};

export type IGcalEvent = {
  id: string;
  start: {
    dateTime: string;
    timeZone: string;
  };
  end: {
    dateTime: string;
    timeZone: string;
  };
};

export type IUserProfile = {
  uid: string;
  email: string;
  avatar: string;
  userName: string;
  flag: string;
  followersCount: number;
  followingsCount: number;
  isOwner: boolean;
  isFollowing: boolean;
  workingStartTime: string;
  workingEndTime: string;
};

export type GloModeratorData = {
  userName?: string;
  avatar?: string;
  resizedImages?: {
    medium: string;
    small: string;
  };
};

export type AvatarSize = 'small' | 'medium' | undefined;

export type IPaginatedData = {
  totalFeeds: number;
  data: IFeedPost[];
  nextPageToken?: number;
};

export const mixlTopPosts = 'For you';
export const mixlUserCollection = 'Saved';
export const mixlUserPosts = 'Shared';
export const mixlUserAdvanced = 'Account';
export const mixlUserTrack = 'Track';

export type mixlPlaybackType = 'Saved' | 'Shared';

export type feedType = 'savedPosts' | 'sharedPosts';
export interface ISongPreview {
  imageUrl: string;
  postId: string;
  feedType: feedType;
}

export type ISession = {
  title?: string;
  startTime: number;
  endTime: number;
};
export interface IMixlData {
  sessionStarted: boolean;
  mixlCalendarEvents: Array<IMixlEvent>;
  clickupTasks: Array<IMixlTask>;
  splitSessions: ISession[];
  blockedSites: string[];
  songPreview: ISongPreview;
  mixlPlaybakType?: mixlPlaybackType;
  mixlPlaybackItem: IFeedPost;
  selectedUserProfile?: IUserProfile;
  currentSessionId?: string;
  userBlockedTime: number;
  publishToSlack: boolean;
  milestones: IMileStone[];
  blockTimeGoal: string;
}

export interface IMusicContent {
  content: unknown;
  contentType: 'feed';
}

export interface IMileStone {
  task_id: number;
  task: string;
  description: string;
  time_estimation: number;
  status: string;
}

export type IFeedPost = {
  compressedGeneratedImage?: string;
  content?: {
    track?: {
      binaural_music_url?: string;
      artwork_url: string;
      permalink_url: string;
      user?: {
        avatar_url?: string;
      };
    };
  };
  generatedImage?: string;
  id: string;
  mood?: string;
  avatar_url?: string;
  timestamp?: number;
  userInfo?: {
    email: string;
    name: string;
    picture: string;
    uid: string;
  };
};

export type IFeesData = {
  totalFeeds: number;
  data: IFeedPost[];
  nextPageToken: undefined | string;
};

const isDEV = import.meta.env.DEV;

export const defaultBlockTimeInMinutes = isDEV ? 60 : 60;

export const TaskDayTypes = {
  today: 'today',
  later: 'todo',
  done: 'done'
};

export const TaskTagTypes = {
  growth: 'growth',
  market: 'market',
  planning: 'planning',
  engineering: 'engineering'
};

export const TaskTypesList = [
  {
    name: TaskDayTypes.later,
    color: '#42ACDA',
    value: TaskDayTypes.later
  },
  {
    name: TaskDayTypes.today,
    color: '#FE4D8A',
    value: TaskDayTypes.today
  },
  {
    name: TaskDayTypes.done,
    color: '#9e9e9e',
    value: TaskDayTypes.done
  }
];
