import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore';
import { random } from 'lodash';
import { DateTime } from 'luxon';
import { MixlAudioPlayer } from 'mixl-audio';
import { toast } from 'react-toastify';

import endSessionAudio from '../../assets/Loaders/session_ends.mp3';
import { firebaseAuth, firebaseDB, getUserToken, recordAnalytics } from '../../components/firebase/firebase';
import { ALLOWED_DURATION_FOR_REVIEW } from '../../constants/theme';
import {
  convertMixlEvent,
  deleteDaySchedule,
  fetchDaySchedule,
  fetchMixlTaskList,
  fetchScheduleToday,
  getMixlEventsWithTaskData,
  getUnscheduledTaskList,
  saveDaySchedule
} from '../../Services/CalendarService';
import { fetchClickupTasks } from '../../Services/ClickupService';
import { getQuoteBreak, getQuoteWarmup } from '../../Services/feedService';
import Request from '../../Services/Request';
import {
  getBlockingSites,
  getMilestones,
  getTaskCategory,
  getTaskPriority,
  updateClickupTaskProgress
} from '../../Services/TaskService';
import { ISlackCredential, IUserSlackCredential } from '../../Types/mixlTypes';
import { convertTasksToEvents, getWorkingHours, sortByPriority } from '../../utils/flowCoachUtils';
import { sleep } from '../../utils/miscUtils';
import { flowStates, mixlTabs, MUSIC_STATIONS } from '../../utils/mixlFlow';
import { isFinalHourAvailable, scheduleMixlTasks } from '../../utils/mixlScheduleHelper';
import { prioritiesList } from '../../utils/musicFeedUtils';
import {
  inIframe,
  isCapacitor,
  isExtenstionSidebar,
  isOffscreenIframe,
  postIframeMessage
} from '../../utils/platformUtils';
import { IMixlEvent, IMixlTask, IWorkingHours } from '../../utils/Types';
import {
  pauseMusicTrack,
  pauseMusicTrackAction,
  playMusicTrack,
  playMusicTrackAction,
  resumeMusicTrackAction
} from '../player/action';
import { playerActionTypes } from '../player/types';
import { RootState } from '../root-reducer';
import { getAiSessionData, handleNotificationsBadges } from './functions';
import { default_goal_list, defaultBlockTimeInMinutes, ISession, mixlActionTypes } from './types';

export const defaultMilestoneTime = 300;
export const defaultShortMilestoneTime = 1800;

export const sessionLengthThreshold = 1800;

export const warmupTime = 10;
// export const defaultMilestoneTime = 60;
// export const defaultShortMilestoneTime = 300;

const { PostSecuredData, GetSecuredData } = Request();

export const startGeneratingTaskData = (taskId) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.ADD_TASK_DATA_QUEUE,
    taskId
  });
};

export const endGeneratingTaskData = (taskId) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.REMOVE_TASK_DATA_QUEUE,
    taskId
  });
};

export const setFullProgressAfterSongIsFinished = () => {
  return async (dispatch, getState) => {
    const store: RootState = getState();
    const fullProgressValue = store?.mixlData?.fullProgressValue;
    const playerProgress = store?.feedPlayerData?.playerProgress;
    dispatch({
      type: mixlActionTypes.SET_FULL_PROGRESS_MUSIC,
      payload: fullProgressValue + playerProgress
    });
  };
};

export const fetchMusicExtenally = (song) => (dispatch, getState) => {
  const store: RootState = getState();
  const currentMixlScreen = store.mixlData.currentMixlScreen;
  const stopMusic = localStorage.getItem('stopMusic');
  const stopTimer = localStorage.getItem('stopTimer');

  if (stopMusic === 'true') {
    dispatch(pauseMusicTrackAction());
    if (stopTimer !== 'true') dispatch(setShowCountdownTimer(true));
    return;
  }

  const phaseBreak = currentMixlScreen === 'break';
  const showCalmPhase = phaseBreak;
  const userBlockedTime = store.mixlData.userBlockedTime;
  dispatch(addToPlayerProgress(0));
  dispatch({
    type: mixlActionTypes.SET_CURRENT_PHASE,
    payload: showCalmPhase ? flowStates.calm.phase : flowStates.focus.phase
  });
  dispatch({
    type: mixlActionTypes.SET_FULL_PROGRESS_MUSIC,
    payload: 0
  });
  dispatch({
    type: mixlActionTypes.SET_FULL_CURRENT_MUSIC,
    payload: song
  });
  dispatch({
    type: mixlActionTypes.SET_CURRENT_MUSIC,
    payload: showCalmPhase ? song.calm_phase : song.focus_phase
  });

  dispatch({
    type: mixlActionTypes.FETCHING_PLAYLIST,
    payload: false
  });
  if (userBlockedTime > 0 && stopTimer !== 'true' && stopMusic !== 'true') {
    dispatch(
      playMusicTrackAction({
        content: showCalmPhase ? song.calm_phase : song.focus_phase,
        contentType: 'feed'
      })
    );
  }
  if (stopTimer !== 'true') dispatch(setShowCountdownTimer(true));
};

export const fetchingPlaylist = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.FETCHING_PLAYLIST,
    payload
  });
};

const saveMusic = async (song, currentSessionId) => {
  if (!currentSessionId) {
    return;
  }
  //save music in session data to persist current music
  const uid = firebaseAuth.currentUser?.uid;
  const flowSessionsRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
  const snapshotflowSessions = await getDoc(flowSessionsRef);
  if (snapshotflowSessions.exists()) {
    await updateDoc(flowSessionsRef, {
      currentMusic: song
    });
  }
};

export const fetchMixlMusic = (disableLoader, userInitiated, forceNotPlaying) => async (dispatch, getState) => {
  console.log('fetchMixlMusic');
  const store: RootState = getState();
  const musicStation = store.mixlData.musicStation;
  if (musicStation === 'nomusic') {
    return;
  }

  const fullCurrentMusicData = store.mixlData.fullCurrentMusicData;
  const currentMusic = store.mixlData.currentMusic;
  const userBlockedTime = store.mixlData.userBlockedTime;
  const sessionStarted = store.mixlData.sessionStarted;
  const currentSessionId = store.mixlData.currentSessionId;

  const currentMixlScreen = store.mixlData.currentMixlScreen;
  let isMusicPlaying = store.feedPlayerData.isMusicPlaying;
  let musicPlaylists = store.mixlData.musicPlaylists;
  const phaseBreak = currentMixlScreen === 'break';
  const showCalmPhase = phaseBreak;
  const playlist_name = showCalmPhase
    ? 'ambient'
    : MUSIC_STATIONS.find((e) => e.name === musicStation)?.abrev || MUSIC_STATIONS[0].abrev;

  if (userInitiated && isExtenstionSidebar()) {
    isMusicPlaying = store.feedPlayerData.isMusicPlaying;
    if (isMusicPlaying && !forceNotPlaying) {
      await dispatch(pauseMusicTrack());
    }
  }
  // if timer not ended yet after refresh
  if (!disableLoader) {
    dispatch({
      type: mixlActionTypes.FETCHING_PLAYLIST,
      payload: true
    });
    if (isOffscreenIframe()) {
      postIframeMessage({ type: 'fetching_music', fetching: true });
    }
    await sleep(3000);
  }

  try {
    // init music playlist
    if (!musicPlaylists) {
      musicPlaylists = await fetchPlaylistsData();
      dispatch({
        type: mixlActionTypes.SET_MUSIC_LIST,
        payload: musicPlaylists
      });
    }

    let playlist = musicPlaylists[playlist_name];
    if (playlist && fullCurrentMusicData && fullCurrentMusicData?.hasMoreSongs && fullCurrentMusicData?.timestamp) {
      const checkNewList = playlist.filter((station) => station.timestamp > fullCurrentMusicData?.timestamp);
      if (checkNewList.length) playlist = checkNewList;
    }
    if (playlist?.length > 0) {
      let newSongIndex = 0;
      if (!currentMusic) {
        newSongIndex = random(0, playlist?.length - 1, false);
      }
      if (!playlist.length) {
        throw new Error('No music found');
      }
      const songData = playlist[newSongIndex];
      const hasMoreSongs = playlist.length - newSongIndex > 1;
      const calm_phase = {
        ...songData.calm_phase
      };
      const focus_phase = {
        ...songData.focus_phase
      };
      const song = {
        hasMoreSongs,
        calm_phase: calm_phase,
        focus_phase: focus_phase,
        timestamp: songData.timestamp,
        id: songData.id
      };

      if (userInitiated && isExtenstionSidebar() && !forceNotPlaying) {
        console.log('userInitiated: sending refresh_music command ', song, songData);
        postIframeMessage({ type: 'refresh_music', song });
      }

      // check the phase of the milestone

      dispatch({
        type: mixlActionTypes.SET_FULL_PROGRESS_MUSIC,
        payload: 0
      });
      dispatch({
        type: mixlActionTypes.SET_CURRENT_PHASE,
        payload: showCalmPhase ? flowStates.calm.phase : flowStates.focus.phase
      });
      dispatch({
        type: mixlActionTypes.SET_CURRENT_MUSIC,
        payload: showCalmPhase ? songData.calm_phase : songData.focus_phase
      });
      dispatch({
        type: mixlActionTypes.SET_FULL_CURRENT_MUSIC,
        payload: song
      });
      dispatch({
        type: mixlActionTypes.FETCHING_PLAYLIST,
        payload: false
      });
      if (isOffscreenIframe()) {
        postIframeMessage({ type: 'fetching_music', fetching: false });
      }
      if (userBlockedTime > 0 && (userInitiated || sessionStarted) && !forceNotPlaying) {
        dispatch(
          playMusicTrack({ content: showCalmPhase ? songData.calm_phase : songData.focus_phase, contentType: 'feed' })
        );
      }

      saveMusic(song, currentSessionId);

      recordAnalytics('fetchMusicSuccess');
    } else {
      dispatch({
        type: mixlActionTypes.FETCHING_PLAYLIST,
        payload: false
      });
      toast.success('No music found', {
        className: 'slow-toast',
        autoClose: 2000
      });
    }
    const stopTimer = localStorage.getItem('stopTimer');
    if (stopTimer !== 'true') dispatch(setShowCountdownTimer(true));
  } catch (err) {
    console.log('error: ', err);
    recordAnalytics('fetchMusicError');
    dispatch({
      type: mixlActionTypes.FETCHING_PLAYLIST,
      payload: false
    });
  }
};

export const addToPlayerProgress = (value) => {
  return async (dispatch) => {
    if (isOffscreenIframe()) {
      postIframeMessage({ type: 'player_progress', progressValue: value });
    }
    dispatch({
      type: playerActionTypes.UPDATE_PLAYER_PROGRESS,
      payload: {
        playerProgress: value
      }
    });
  };
};

export const updateSessionId = (sessionId) => async (dispatch) => {
  if (inIframe()) {
    postIframeMessage({ sessionId, type: 'sessionId' });
  }
  dispatch({
    type: mixlActionTypes.UPDATE_SESSION_ID,
    sessionId
  });
};

// create goal session
export const createSession = (persistSession) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { selectedTaskClickup, breakTime, blockedSites, fullCurrentMusicData } = store.mixlData;

  const userBlockedTime = store.mixlData.userBlockedTime || defaultBlockTimeInMinutes * 60;
  const uid = firebaseAuth.currentUser?.uid;

  const timestamp = Date.now();
  const taskName = selectedTaskClickup?.name ?? '';
  //get initial time from storage, because the one in store counts down
  const sessionLength = userBlockedTime;

  const docRef = doc(collection(firebaseDB, 'glo-users', uid, 'flow_sessions'));
  const sessionId = docRef.id;
  const timeCompleted = 0;
  const goalCompletion = null;
  const payload = {
    sessionId,
    blockedSites,
    timestamp,
    taskName,
    sessionLength,
    timeCompleted,
    goalCompletion,
    taskClickup: selectedTaskClickup?.id ?? '',
    syncCurrentTime: timestamp,
    description: '',
    breakTime,
    currentMusic: fullCurrentMusicData,
    ...selectedTaskClickup
  };

  console.log('flow_sessions: ', payload);

  await setDoc(docRef, payload);
  if (persistSession) {
    dispatch(updateSessionId(sessionId));
    dispatch(setUserBlockTimeGoal(taskName));
  }
  return payload;
};

export const startFocusSession = () => async (dispatch, getState) => {
  const store: RootState = getState();
  const uid = firebaseAuth.currentUser?.uid;
  const { userBlockedTime, currentSessionId, milestones, sessionLength, selectedTaskClickup } = store.mixlData;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);

  if (!userBlockedTime || !currentSessionId) {
    return;
  }
  const selectedGoal = selectedTaskClickup?.name;
  dispatch(fetchBlockedSites(selectedGoal, sessionLength));
  const taskProgressText = 'in progress';
  dispatch(
    editMixlTask({
      ...selectedTaskClickup,
      status: { status: taskProgressText }
    })
  );
  console.log('startFocusSession: ', selectedTaskClickup);
  if (selectedTaskClickup?.type === 'clickup' && selectedTaskClickup?.status?.status === 'Open') {
    const taskId = selectedTaskClickup?.id;
    updateClickupTaskProgress(taskId, taskProgressText);
  }

  const initialBlockedTime = userBlockedTime;

  const snapshot = await getDoc(docRef);
  if (snapshot.exists()) {
    const currentMilestones = [...milestones];
    await updateDoc(docRef, {
      startedSession: true,
      milestones: [...currentMilestones]
    });
    dispatch({
      type: mixlActionTypes.UPDATE_SESSION_STATUS,
      payload: true
    });
  }

  const timestamp = Date.now();
  // start timer
  if (snapshot.exists()) {
    await updateDoc(docRef, { syncCurrentTime: timestamp });
  }
  const statusRef = doc(firebaseDB, 'glo-users', uid, 'sessions_status', 'currentSession');
  await setDoc(statusRef, {
    sessionId: currentSessionId,
    active: true
  });
  if (isCapacitor) {
    dispatch(setShowCountdownTimer(true));
    dispatch(fetchMixlMusic(true, true));
  }
  dispatch(setSessionLength(initialBlockedTime));

  if (isExtenstionSidebar()) {
    postIframeMessage({ type: 'background_music', command: 'start_music' });
    postIframeMessage({ type: 'startSession' });
    dispatch(setShowCountdownTimer(true));
    dispatch(fetchMixlMusic(true, true));
  }
};

export const setUserBlockTime = (value) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_TIMER_VALUE,
    payload: value
  });
};

export const setUserBlockTimeGoal = (value) => (dispatch) => {
  if (inIframe()) {
    postIframeMessage({ taskName: value, type: 'taskName' });
  }
  dispatch({
    type: mixlActionTypes.SET_BLOCK_TIME_GOAL,
    payload: value
  });
};

export const setShowCountdownTimer = (value) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { userBlockedTime } = store.mixlData;
  dispatch({
    type: mixlActionTypes.SET_STOP_START_TIMER,
    payload: value
  });
  if (isCapacitor) {
    if (!value) await MixlAudioPlayer.stopPermanentlyTimer();
    else await MixlAudioPlayer.startSessionTimer({ sessionLength: userBlockedTime });
  }
};

export const maybeCloseWindow = () => (dispatch, getState) => {
  const store = getState() as RootState;
  const { currentSessionId, sessionStarted } = store.mixlData;
  if (currentSessionId && sessionStarted) {
    window.CapacitorCustomPlatform.runAppInBackground();
    return;
  }
  window.CapacitorCustomPlatform.closeMixlApp();
};

export const fetchBlockedSites = (task, sessionLength) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { currentSessionId, blockedSites, blockAllSites } = store.mixlData;
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);

  dispatch({
    type: mixlActionTypes.SET_BLOCK_ALL_SITES,
    payload: blockAllSites || false
  });

  console.log('fetchBlockedSites: blocking all sites ', currentSessionId, blockedSites, blockAllSites);
  const sitesData = [];
  if (blockedSites.length) {
    const siteNames = blockedSites.filter((e) => e.Tag === 'Block').map((e) => e.Website);
    sitesData.push(...siteNames);
  } else {
    console.log('fetchBlockedSites: no block site found checking database');
    const snapshot = await getDoc(docRef);
    const data = snapshot.data();
    const blockedSitesData = data?.blockedSites ?? [];
    const siteNames = blockedSitesData.filter((e) => e.Tag === 'Block').map((e) => e.Website);
    sitesData.push(...siteNames);
  }

  if (sitesData.length) {
    console.log('fetchBlockedSites: setting site blocker ', sitesData);
    postIframeMessage({
      type: 'blocked_sites',
      command: 'save_list',
      blockedSites: sitesData
    });
    return;
  }
  console.log('fetchBlockedSites: generarating new site blocker data');
  dispatch({
    type: mixlActionTypes.SET_GENERATING_BLOCKED_SITES,
    payload: true
  });
  const recommendedResult = await getBlockingSites(task, sessionLength);
  const sites = recommendedResult?.sites ?? [];
  dispatch({
    type: mixlActionTypes.SET_BLOCKED_SITES,
    payload: sites
  });
  dispatch({
    type: mixlActionTypes.SET_BLOCK_ALL_SITES,
    payload: false
  });
  dispatch({
    type: mixlActionTypes.SET_GENERATING_BLOCKED_SITES,
    payload: false
  });
  await updateDoc(docRef, {
    blockedSites: sites
  });
  postIframeMessage({
    type: 'blocked_sites',
    command: 'save_list',
    blockedSites: sites.filter((e) => e.Tag === 'Block').map((e) => e.Website)
  });
};

export const setBreakType = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_BREAK_TYPE,
    payload: payload
  });
};
export const setEndAudioPlayed = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.PLAYED_END_SESSION_AUDIO,
    payload: payload
  });
};
export const reduceBlockTimerOneSecond = (remainingTime?: number) => async (dispatch, getState) => {
  let newValueSeconds = remainingTime;
  const store: RootState = getState();
  // prettier-ignore
  const { 
    userBlockedTime, sessionLength, currentMixlScreen,
    breakTime, showTimerCounting, playedEndSessionAudio
  } = store.mixlData;
  const currentSessionId = store.mixlData.currentSessionId;

  if (!currentSessionId) {
    return;
  }
  if (isCapacitor) {
    if (userBlockedTime < newValueSeconds) {
      newValueSeconds = userBlockedTime - 1;
    }
  }
  if (isNaN(remainingTime)) {
    newValueSeconds = userBlockedTime - 1;
  }

  if (newValueSeconds < 0) {
    newValueSeconds = 0;
  }

  if (sessionLength - newValueSeconds >= warmupTime && currentMixlScreen === mixlTabs.warmup) {
    dispatch(setMixlScreen(mixlTabs.focus));

    const uid = firebaseAuth.currentUser?.uid;
    const flowSessionsRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
    const snapshotflowSessions = await getDoc(flowSessionsRef);
    if (snapshotflowSessions.exists()) {
      await updateDoc(flowSessionsRef, {
        notifWork: true
      });
    }
  }

  if (newValueSeconds && newValueSeconds <= breakTime && currentMixlScreen !== mixlTabs.break) {
    console.log('reduceBlockTimerOneSecond: is ready for break?');
    dispatch(pauseMusicTrackAction());
    dispatch(setshowFocusDetailsModal(true));
    if (!isExtenstionSidebar() && !playedEndSessionAudio) {
      const audio = new Audio(endSessionAudio);
      await sleep(500);
      audio.play();
      dispatch(setEndAudioPlayed(true));
    }
  }

  if (isOffscreenIframe() && newValueSeconds > 0) {
    console.log('taskTime', newValueSeconds, breakTime, 'showTimerCounting', showTimerCounting);
    if (showTimerCounting) postIframeMessage({ remainingTime: newValueSeconds, breakTime, type: 'taskTime' });
  }

  dispatch({
    type: mixlActionTypes.SET_TIMER_VALUE,
    payload: newValueSeconds
  });

  const sessionId = store.mixlData.currentSessionId;
  if (newValueSeconds % 10 === 0) {
    await updateSessionTimer(sessionId, newValueSeconds).then(() => {
      dispatch(updateSessionData(sessionId));
    });
  }
};

export const updateSessionData = (sessionId) => async (dispatch) => {
  const uid = firebaseAuth.currentUser?.uid;
  if (!sessionId) {
    return;
  }

  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', sessionId);
  const snapshot = await getDoc(docRef);
  if (snapshot.exists()) {
    const sessionData = snapshot.data();
    dispatch({
      type: mixlActionTypes.SET_SESSION_DATA,
      payload: sessionData
    });
  }
};

export const updatePlayedMusic = () => {
  return async (dispatch) => {
    dispatch(fetchMixlMusic(true, false));
  };
};

export const addBlockedWebsite = (siteName) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { currentSessionId, blockedSites } = store.mixlData;
  const uid = firebaseAuth.currentUser?.uid;
  if (blockedSites.includes(siteName)) {
    return;
  }
  const newSite = {
    Description: siteName,
    Time: 300,
    Website: siteName,
    color: '#8DCD76',
    // icon: '/src/assets/icons/link-black-icon.svg',
    Tag: 'Block'
  };

  dispatch({
    type: mixlActionTypes.ADD_BLOCKED_SITE,
    siteName: newSite
  });
  postIframeMessage({
    type: 'blocked_sites',
    command: 'save_list',
    blockedSites: [...blockedSites, newSite].filter((e) => e.Tag === 'Block').map((e) => e.Website)
  });
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
  const snapshot = await getDoc(docRef);
  if (snapshot.exists()) {
    await updateDoc(docRef, {
      blockedSites: [
        ...blockedSites,
        {
          Description: siteName,
          Time: 5,
          Website: siteName,
          color: '#8DCD76',
          // icon: '/src/assets/icons/link-black-icon.svg',
          Tag: 'Block'
        }
      ]
    });
  }
};

export const checkMobileTimer = (id, addOffset) => async (dispatch, getState) => {
  const store: RootState = getState();

  const { currentSessionId } = store.mixlData;
  const sessionId = currentSessionId || id;
  if (!sessionId) {
    return;
  }

  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', sessionId);
  const snapshot = await getDoc(docRef);
  const sessionData = snapshot.data();

  if (sessionData) {
    const stopTimer = localStorage.getItem('stopTimer') === 'true';
    const newDuration = getTimerValue(sessionData, stopTimer);
    dispatch({
      type: mixlActionTypes.SET_TIMER_VALUE,
      payload: newDuration
    });
    dispatch({
      type: mixlActionTypes.SET_SESSION_FULL_TIME,
      payload: sessionData.sessionLength
    });
    dispatch({
      type: mixlActionTypes.SET_BREAK_TIME,
      payload: sessionData.breakTime
    });
    dispatch({
      type: mixlActionTypes.SET_SKIPPED_WARMUP,
      payload: sessionData.skippedWarmup ?? false
    });

    if (newDuration <= 0) {
      if (addOffset) {
        const reviewDuration = newDuration + ALLOWED_DURATION_FOR_REVIEW;
        if (reviewDuration > 0) {
          dispatch(setshowFocusDetailsModal(true));

          return false;
        }
      }
      return true;
    }
  }
};

export const shouldShowRestartSessionModal = (status) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SHOW_RESTART_SESSION_MODAL,
    status
  });
};

export const showGlobalLoadingAlert = (isOpen) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_END_SESSION_LOADING,
    payload: isOpen
  });
};

export const setBlockedList = (payload) => async (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_BLOCKED_SITES,
    payload
  });
};

export const clearBlockedList = () => async (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_BLOCKED_SITES,
    payload: []
  });
  //clear blocked sites
  postIframeMessage({ type: 'blocked_sites', command: 'clear_list' });
};

export const clearBlockAllSites = () => async (dispatch) => {
  postIframeMessage({
    type: 'block_all_sites',
    command: 'clear_list',
    allowedSites: []
  });

  dispatch({
    type: mixlActionTypes.SET_BLOCK_ALL_SITES,
    payload: false
  });
};

export const saveTimeCompleted = (sessionId) => async (dispatch, getState) => {
  const store: RootState = getState();
  const uid = firebaseAuth.currentUser?.uid;
  const { userBlockedTime } = store.mixlData;
  const id = store.mixlData.currentSessionId;
  const currentSessionId = id || sessionId;

  // update active status of the session
  if (!currentSessionId) {
    return;
  }
  const flowSessionsRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
  const snapshotflowSessions = await getDoc(flowSessionsRef);
  if (snapshotflowSessions.exists()) {
    const sessionData = snapshotflowSessions.data();
    const sessionLength = sessionData?.sessionLength;
    const timeCompleted = sessionLength - userBlockedTime;
    await updateDoc(flowSessionsRef, {
      startedSession: false,
      timeCompleted
    });
  }
};

export const endFocusSession = (sessionId) => async (dispatch, getState) => {
  const store: RootState = getState();
  const uid = firebaseAuth.currentUser?.uid;
  const { shouldClearTabs } = store.mixlData;
  const id = store.mixlData.currentSessionId;
  const currentSessionId = id || sessionId;

  dispatch({
    type: mixlActionTypes.SET_END_SESSION_LOADING,
    payload: true
  });

  // update active status of the session
  if (!currentSessionId) {
    return;
  }

  const payload = {
    active: false
  };
  const docRef = doc(firebaseDB, 'glo-users', uid, 'sessions_status', 'currentSession');
  const statusSnapshot = await getDoc(docRef);
  if (statusSnapshot.exists()) {
    await updateDoc(docRef, payload);
  }
  dispatch(setMixlScreen(mixlTabs.initial));
  dispatch(updateSessionId());
  dispatch(setUserBlockTimeGoal(''));
  // dispatch(setSessionLength(Number(60 * 60)));
  // dispatch(setBreakTime(Number(10 * 60)));
  if (isCapacitor) {
    await MixlAudioPlayer.stopSessionTimer();
  }
  dispatch(pauseMusicTrackAction());

  if (isExtenstionSidebar()) {
    postIframeMessage({ type: 'background_music', command: 'stop_music' });
    if (shouldClearTabs) postIframeMessage({ type: 'clear_tabs' });
    postIframeMessage({
      type: 'end_session',
      badge: '',
      station: ''
    });
  }
  dispatch({
    type: mixlActionTypes.UPDATE_SESSION_STATUS,
    payload: false
  });

  // finish loading
  dispatch({
    type: mixlActionTypes.SET_END_SESSION_LOADING,
    payload: false
  });

  const token = await getUserToken();
  await GetSecuredData('authapi/runUpdateStarterBadge', token);
  await GetSecuredData('authapi/runUpdateSteadyBadge', token);
  await GetSecuredData('authapi/runUpdateFlowBadge', token);

  // UPDATE OUR TEAM DATA
  console.log('endFocusSession: fetching updated teamdata');
  //edit period duration if user ended the session
  const newStations = await handleNotificationsBadges();
  dispatch(updateStations(newStations));
  //update ai cards data
  const aiSessionsData = await getAiSessionData();
  dispatch(setAiSessionsData(aiSessionsData));

  localStorage.removeItem('stopTimer');
  dispatch(updateSessionData(null));
  // clear timer if paused
  dispatch(setShowCountdownTimer(true));
};

export const setBlockWebsite = (url) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { blockedSites, currentSessionId } = store.mixlData;
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);

  const updated_sites = blockedSites.map((site) => (site.Website === url ? { ...site, Tag: 'Block' } : { ...site }));
  dispatch(setBlockedList(updated_sites));
  await updateDoc(docRef, {
    blockedSites: blockedSites
  });
};

export const toggleBlockAllSites = () => async (dispatch, getState) => {
  const store: RootState = getState();
  const { blockAllSites, blockedSites, currentSessionId } = store.mixlData;
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
  await updateDoc(docRef, {
    blockAllSites: !blockAllSites
  });
  console.log('blockAllSites====', blockAllSites);
  if (blockAllSites === false) {
    postIframeMessage({
      type: 'block_all_sites',
      command: 'save_list',
      allowedSites: blockedSites.filter((e) => e.Tag === 'Allow').map((e) => e.Website)
    });
  } else {
    postIframeMessage({
      type: 'block_all_sites',
      command: 'clear_list',
      allowedSites: blockedSites.filter((e) => e.Tag === 'Allow').map((e) => e.Website)
    });
  }
  dispatch({
    type: mixlActionTypes.SET_BLOCK_ALL_SITES,
    payload: !blockAllSites
  });
};

export const handleSelectionSite = (url) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { blockedSites, currentSessionId } = store.mixlData;

  const updated_sites = blockedSites.map((site) =>
    site.Website === url ? { ...site, Tag: site.Tag === 'Allow' ? 'Block' : 'Allow' } : { ...site }
  );
  dispatch(setBlockedList(updated_sites));

  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);

  postIframeMessage({
    type: 'blocked_sites',
    command: 'save_list',
    blockedSites: updated_sites.filter((e) => e.Tag === 'Block').map((e) => e.Website)
  });

  await updateDoc(docRef, {
    blockedSites: updated_sites
  });
};

export const SyncSessionData = (snapshot) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { currentSessionId, currentMixlScreen } = store.mixlData;
  const { isMusicPlaying } = store.feedPlayerData;
  if (currentSessionId) {
    return;
  }
  console.log('SyncSessionData: currentSessionId ', snapshot.exists());

  if (!snapshot.exists()) {
    console.log('Document not found');
    return;
  }

  const sessionData = snapshot.data();
  const session = await getSession(sessionData.sessionId);
  const stopTimer = localStorage.getItem('stopTimer');
  const stopMusic = localStorage.getItem('stopMusic');
  // console.log('SyncSessionData: ', sessionData?.active, sessionData);
  if (sessionData?.active) {
    if (!session?.notifBreak) {
      if (!currentMixlScreen) dispatch(setMixlScreen(mixlTabs.focus));
    }
    const shouldEnd = await dispatch(checkMobileTimer(session?.sessionId, true));
    if (shouldEnd) {
      console.log('SyncSessionData: we should end session here');
      dispatch(endFocusSession(session?.sessionId, shouldEnd));
      return;
    }
    if (isCapacitor && stopTimer !== 'true') {
      await MixlAudioPlayer.startSessionTimer({ sessionLength: session?.sessionLength });
    }
    const isSidebar = isExtenstionSidebar();
    if (isSidebar && stopTimer !== 'true' && stopMusic !== 'true') {
      postIframeMessage({ type: 'background_music', command: 'start_music' });
      dispatch(resumeMusicTrackAction());
    }
    dispatch(updateSessionId(session?.sessionId));
    dispatch(setUserBlockTimeGoal(session?.taskName));
    if (!session?.blockedSites?.length) {
      dispatch(fetchBlockedSites(session?.taskClickup?.name || session?.taskName, session?.sessionLength));
    } else {
      const blockedSites = session?.blockedSites ?? [];
      if (blockedSites?.length) {
        const siteNames = blockedSites.filter((e) => e.Tag === 'Block').map((e) => e.Website);
        postIframeMessage({
          type: 'blocked_sites',
          command: 'save_list',
          blockedSites: siteNames
        });
      }
      dispatch({
        type: mixlActionTypes.SET_BLOCKED_SITES,
        payload: blockedSites
      });
      dispatch({
        type: mixlActionTypes.SET_BLOCK_ALL_SITES,
        payload: session?.blockAllSites
      });
    }
    console.log('SyncSessionData: isMusicPlaying ', isMusicPlaying);
    // !! DO NOT TOUCH !!
    if (!isMusicPlaying) {
      dispatch(fetchMixlMusic(true, !isSidebar));
    } else {
      dispatch(setShowCountdownTimer(true));
      dispatch({
        type: mixlActionTypes.SET_CURRENT_MUSIC,
        payload: session?.notifBreak ? session?.currentMusic?.calm_phase : session?.currentMusic?.focus_phase
      });
      dispatch({
        type: mixlActionTypes.SET_FULL_CURRENT_MUSIC,
        payload: session?.currentMusic
      });
    }
    const taskClickup = session?.taskClickup;
    if (taskClickup) {
      console.log('sessionData=== ', session);
      const uid = firebaseAuth.currentUser?.uid;
      const clickupTaskRef = doc(firebaseDB, 'glo-users', uid, 'mixl_tasklist', taskClickup);
      const taskData = await getDoc(clickupTaskRef)
        .then((taskSnapshot) => {
          if (!taskSnapshot.exists) {
            return {};
          }
          const data = taskSnapshot.data();
          console.log('taskData===', data);
          if (data) {
            return { ...data, id: taskClickup };
          }
          return { ...session, id: taskClickup };
        })
        .catch((err) => {
          console.log('SyncSessionDataerr: ', err);
          return {};
        });

      dispatch(setSelectedTaskClickup({ id: taskClickup, ...taskData }));
    }
    dispatch({
      type: mixlActionTypes.UPDATE_SESSION_STATUS,
      payload: session.startedSession
    });
  }
};

export const updateCompletedGoal = (sessionId: string, value: number) => async (dispatch) => {
  const uid = firebaseAuth.currentUser?.uid;
  dispatch({
    type: mixlActionTypes.SET_RATING_CURRENT_SESSION,
    payload: value
  });
  if (sessionId) {
    const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', sessionId);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      await updateDoc(docRef, { goalCompletion: value });
    }
  }
};

export const setShowAppUpdateModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SHOW_APP_UPDATE_MODAL,
    payload
  });
};

export const setSelectedTaskClickup = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SELECTED_TASK_CLICKUP,
    payload
  });
};

export const setIsScheduling = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.IS_SCHEDULING,
    payload
  });
};

export const setShowSiteBlockerModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SHOW_SITE_BLOCKER_MODAL,
    payload
  });
};

export const setAddSiteBlockModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SHOW_ADD_BLOCK_SITE_MODAL,
    payload
  });
};

export const editMixlTask = (task: IMixlTask) => async (dispatch, getState) => {
  const store: RootState = getState();
  console.log(task);
  const { clickupTasks, selectedTaskClickup } = store.mixlData;
  const docId = task.id;
  if (!docId) {
    return;
  }
  const uid = firebaseAuth.currentUser?.uid;

  const docRef = doc(firebaseDB, 'glo-users', uid, 'mixl_tasklist', `${docId}`);

  const updatedTask = { ...task };
  if (updatedTask.type === 'custom') {
    await updateDoc(docRef, { ...updatedTask });
    console.log('Document successfully edited!');
  }
  const newTasks = [...clickupTasks].filter((item) => item.id !== docId);
  newTasks.push({ ...updatedTask });
  dispatch({
    type: mixlActionTypes.SET_CLICKUP_LIST_TASKS,
    payload: [...newTasks]
  });
  if (docId === selectedTaskClickup?.id) {
    dispatch({
      type: mixlActionTypes.SET_SELECTED_TASK_CLICKUP,
      payload: { ...updatedTask }
    });
  }
  const showTaskDetailsModal = getState()?.mixlData?.showTaskDetailsModal;
  if (showTaskDetailsModal) {
    dispatch(setShowTaskDetailsModal(updatedTask));
  }
  return updatedTask;
};

export const addQuickMixlTask =
  (taskName: string, otherData = {}) =>
  async (dispatch, getState) => {
    const store: RootState = getState();
    const { clickupTasks } = store.mixlData;
    const uid = firebaseAuth.currentUser?.uid;
    const docRef = doc(collection(firebaseDB, 'glo-users', uid, 'mixl_tasklist'));
    console.log('addQuickMixlTaskdebug: docID ', docRef.id);
    const timeNow = Date.now();
    const newTask: IMixlTask = {
      details: '',
      status: {
        status: 'open'
      },
      name: taskName ?? '',
      isTodayTask: true,
      type: 'custom',
      tagName: '',
      priority: {
        color: '#6fddff',
        id: '3',
        orderindex: '3',
        priority: 'normal'
      },
      createdAt: timeNow,
      id: docRef.id,
      startTimestamp: timeNow,
      hasAnimated: false,
      milestones: [],
      ...otherData
    };

    dispatch({
      type: mixlActionTypes.SET_CLICKUP_LIST_TASKS,
      payload: [...clickupTasks, { ...newTask }]
    });
    console.log('addQuickMixlTaskdebug: newTask', newTask);
    dispatch(startGeneratingTaskData(newTask.id));

    await setDoc(docRef, { ...newTask })
      .then(async () => {
        const autogenData = await autogenTaskData(newTask);
        if (autogenData) {
          const { priorityType, ...rest } = autogenData;
          if (priorityType !== newTask.priority.priority) {
            const priorityItem = prioritiesList.find((item) => item.priority === priorityType);
            if (priorityItem) {
              newTask.priority = { ...priorityItem };
            }
          }
          dispatch(editMixlTask({ ...newTask, ...rest }));
        }
      })
      .finally(() => {
        dispatch(endGeneratingTaskData(newTask.id));
      });

    return newTask;
  };

export const updateClickupTaskList = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_CLICKUP_LIST_TASKS,
    payload
  });
};

export const deleteMixlTask = (task: IMixlTask) => (dispatch, getState) => {
  const store: RootState = getState();
  const { clickupTasks, selectedTaskClickup, mixlCalendarEvents } = store.mixlData;
  const docId = task.id;
  console.log('delete task', docId);
  if (!docId) {
    return;
  }
  dispatch(setShowTaskDetailsModal(null));

  dispatch({
    type: mixlActionTypes.SET_CLICKUP_LIST_TASKS,
    payload: [...clickupTasks].filter((e) => e.id !== task.id)
  });
  if (task.id === selectedTaskClickup?.id) {
    dispatch({
      type: mixlActionTypes.SET_SELECTED_TASK_CLICKUP,
      payload: null
    });
  }
  const hasEvent = mixlCalendarEvents.find((item) => item.id === task.id);
  if (hasEvent) {
    deleteDaySchedule(hasEvent);
    const newEvents = mixlCalendarEvents.filter((item) => item.id !== task.id);
    dispatch(updateMixlCalendarEvents([...newEvents]));
  }

  if (task.type === 'clickup') {
    return;
  }

  const uid = firebaseAuth.currentUser?.uid;

  const docRef = doc(firebaseDB, 'glo-users', uid, 'mixl_tasklist', `${docId}`);

  deleteDoc(docRef)
    .then(() => {
      console.log('Document successfully deleted!');
    })
    .catch((error) => {
      console.error('Error removing document: ', error);
    });
};

export const fetchingTaskList = (isLoading) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.LOADING_CLICKUP_LIST_TASKS,
    payload: isLoading
  });
};

export const handleSelectedStation = (payload) => async (dispatch) => {
  if (payload.noMusic) {
    dispatch(pauseMusicTrackAction());
    dispatch({
      type: mixlActionTypes.UPDATE_MUSIC_STATION,
      payload: 'nomusic'
    });
    const uid = firebaseAuth.currentUser?.uid;
    const docRef = doc(firebaseDB, 'glo-users', uid);
    const snapshot = await getDoc(docRef);
    //  const userData = snapshot.data();
    if (snapshot.exists()) {
      await updateDoc(docRef, { musicStation: 'nomusic' });
    }
    localStorage.setItem('stopMusic', 'true');
    return;
  }
  localStorage.setItem('stopMusic', 'false');

  dispatch({
    type: mixlActionTypes.UPDATE_MUSIC_STATION,
    payload: payload.name
  });
  dispatch(fetchMixlMusic(payload.disableLoader, true));
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid);
  const snapshot = await getDoc(docRef);
  //  const userData = snapshot.data();
  if (snapshot.exists()) {
    await updateDoc(docRef, { musicStation: payload.name });
  } else {
    await setDoc(docRef, { musicStation: payload.name });
  }
};

export const setSelectedStation = (payload) => async (dispatch) => {
  dispatch({
    type: mixlActionTypes.UPDATE_MUSIC_STATION,
    payload
  });
};

export const updatePlayerVolume = (payload) => async (dispatch) => {
  if (isExtenstionSidebar()) {
    postIframeMessage({ type: 'adjust_volume', playerVolume: payload });
  }
  dispatch({
    type: mixlActionTypes.UPDATE_MAIN_VOLUME,
    payload
  });
  localStorage.setItem('playerVolume', payload);
  if (isCapacitor) {
    await MixlAudioPlayer.updateVolume({ volume: parseFloat(payload) });
  }
};

export const setFocusStatsModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SHOW_FOCUS_STATS_MODAL,
    payload
  });
};

export const setShowStationsListModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SHOW_STATIONS_LIST_MODAL,
    payload
  });
};

export const setShowUpdVolumeModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SHOW_UPDATE_VOLUME_MODAL,
    payload
  });
};

export const setShowTaskDetailsModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SHOW_TASK_DETAILS_MODAL,
    payload
  });
};

export const setSessionLength = (value) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.UPDATE_SESSION_LENGTH,
    payload: value
  });
  dispatch({
    type: mixlActionTypes.SET_TIMER_VALUE,
    payload: value
  });
};

export const allowPublishSlack = (data) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.PUBLISH_TO_SLACK,
    payload: data
  });
};

export const updateLikesSong = (song, liked) => async (dispatch, getState) => {
  const store: RootState = getState();
  const musicStation = store.mixlData.musicStation;
  const playlist_name = MUSIC_STATIONS.find((e) => e.name === musicStation)?.abrev;
  const docRef = doc(firebaseDB, 'ai_music', 'place_v13', `${playlist_name}`, `${song.id}`);
  const snapshot = await getDoc(docRef);
  if (snapshot.exists()) {
    const data = snapshot.data();
    const currentLikes = data?.likesList || [];
    const uid = firebaseAuth.currentUser?.uid;
    const index = currentLikes.findIndex((e) => e.uid === uid);
    if (index > -1) {
      currentLikes[index].liked = liked;
    } else {
      currentLikes.push({ uid, liked: liked });
    }
    await updateDoc(docRef, {
      likesList: currentLikes
    });
    dispatch({
      type: mixlActionTypes.SET_FULL_CURRENT_MUSIC,
      payload: { ...song, likesList: currentLikes }
    });
  }
};

export const setBreakTime = (data) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_BREAK_TIME,
    payload: data
  });
};

export const setSelectedOptionTimer = (data) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SELECTED_TIMER_OPTION,
    payload: data
  });
  dispatch({
    type: mixlActionTypes.SET_SESSION_FULL_TIME,
    payload: data.workTime + data.breakTime
  });
  dispatch({
    type: mixlActionTypes.SET_TIMER_VALUE,
    payload: data.workTime + data.breakTime
  });
  dispatch({
    type: mixlActionTypes.SET_BREAK_TIME,
    payload: data.breakTime
  });
};

// todo: are we using it?
export const setSessionFullDuration = (data) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SESSION_FULL_TIME,
    payload: data
  });
  dispatch({
    type: mixlActionTypes.SET_TIMER_VALUE,
    payload: data
  });
};

export const publishSlackPost = (selectedTaskClickup: IMixlTask) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { publishToSlack } = store.mixlData;
  const slackCredential: ISlackCredential = store.businessData.slackCredential;
  if (slackCredential && publishToSlack) {
    const slackToken = slackCredential?.authed_user?.access_token ?? '';
    const scope: string = slackCredential?.authed_user?.scope ?? '';
    if (slackToken && scope.includes('chat:write')) {
      const slackChannels = slackCredential.slackChannels;
      const selectedChannel = slackChannels?.find((item) => item.isSelected);
      if (selectedChannel && selectedChannel.channelId) {
        const slackChannelId = selectedChannel?.channelId;
        // todo: refactor this
        const taskStatus =
          selectedTaskClickup?.status?.status === 'done'
            ? 'Hey, quick update. This task is done: '
            : 'Hey, quick update. This task is in progress: ';
        const taskName = selectedTaskClickup?.name ?? '';
        const taskId = selectedTaskClickup?.id ?? '';
        const messageText = `${taskStatus}\n\n${taskName}. \n\nI’m using Mixl for focus and automatic updates.`;
        const token = await getUserToken();
        await PostSecuredData(
          'community/publish_slack',
          {
            slackToken,
            slackChannelId,
            messageText,
            taskId
          },
          `Bearer ${token}`
        );
      }
    }
  }
};

export const sendSlackMessage =
  (messageText: string, userSlackCredential: IUserSlackCredential, timeLeft: number) => async (dispatch, getState) => {
    const store: RootState = getState();
    const slackCredential: ISlackCredential = store.businessData.slackCredential;

    if (slackCredential && userSlackCredential && messageText) {
      const slackToken = slackCredential?.authed_user?.access_token ?? '';
      const scope: string = slackCredential?.authed_user?.scope ?? '';
      const slackUserId = userSlackCredential?.id ?? '';
      if (slackToken && scope.includes('chat:write') && slackUserId) {
        const token = await getUserToken();
        if (timeLeft <= 0) {
          //post now
          await PostSecuredData(
            'community/send_slack_dm',
            {
              slackToken,
              slackUserId,
              messageText
            },
            `Bearer ${token}`
          );
        } else {
          // Get the current Unix timestamp in seconds
          const currentTimestamp = Math.floor(Date.now() / 1000);

          // Add timeleft seconds to the current timestamp
          const postTimestamp = currentTimestamp + timeLeft;
          console.log('postTimestamp', postTimestamp);
          await PostSecuredData(
            'community/schedule_slack_dm',
            {
              slackToken,
              slackUserId,
              messageText,
              post_at: postTimestamp
            },
            `Bearer ${token}`
          );
        }
      }
    }
  };

export const triggerAutogenTaskData = (newTask: IMixlTask) => async (dispatch, getState) => {
  console.log('triggerAutogenTaskData: starting gen ', newTask);
  dispatch({
    type: mixlActionTypes.SET_GENERATING_BLOCKED_SITES,
    payload: true
  });
  const autogenData = await autogenTaskData(newTask);
  console.log('triggerAutogenTaskData: autogenData ', autogenData);
  if (!autogenData) {
    dispatch({
      type: mixlActionTypes.SET_GENERATING_BLOCKED_SITES,
      payload: false
    });
    return newTask;
  }
  const { priorityType, ...rest } = autogenData;
  if (priorityType !== newTask.priority.priority) {
    const priorityItem = prioritiesList.find((item) => item.priority === priorityType);
    if (priorityItem) {
      newTask.priority = { ...priorityItem };
    }
  }
  const result = { ...newTask, ...rest };
  dispatch(editMixlTask(result));
  const store = getState() as RootState;
  const { stations, musicStation } = store.mixlData;
  const unselectedStations = [...stations].filter((item) => item.name !== musicStation);
  const stationIndex = random(0, unselectedStations.length - 1, false);
  const newStation = unselectedStations[stationIndex];
  await dispatch(handleSelectedStation({ name: newStation.name, disableLoader: true }));
  dispatch({
    type: mixlActionTypes.SET_GENERATING_BLOCKED_SITES,
    payload: false
  });

  return result;
};

export const autogenTaskData = async (newTask: IMixlTask) => {
  const taskName = newTask.name;
  if (!taskName || taskName.trim().split(' ') < 3) {
    return;
  }
  const taskPayload = { taskName };
  const genRequests = [
    getMilestones(taskPayload),
    getTaskPriority(taskPayload),
    getTaskCategory(taskPayload)
    // ,getBlockingSites(taskName)
  ];

  const autogenData = await Promise.allSettled(genRequests).then((results) => {
    const taskData = { details: '', tagName: '', priorityType: 'normal', sites: [] };
    for (const result of results) {
      if (result.status === 'fulfilled') {
        console.log('genRequests: result ', result);
        const resultType = result.value.type;
        switch (resultType) {
          case 'description': {
            const descriptionResult: string = result.value?.description ?? '';
            if (descriptionResult) {
              taskData.details = descriptionResult.replaceAll('\n ', '\n');
            }
            break;
          }
          case 'milestones': {
            const milestones = result.value?.milestones;
            taskData.milestones = milestones;
            break;
          }
          case 'tagName': {
            const tagResult = result.value?.tagName;
            if (tagResult) {
              taskData.tagName = tagResult;
            }
            break;
          }
          case 'priority': {
            const priorityValue = result.value?.priority ?? '';
            if (priorityValue) {
              taskData.priorityType = priorityValue;
            }
            break;
          }
          case 'blockedSites': {
            const sitesData = result.value?.sites ?? [];
            if (sitesData?.length > 0) {
              const sites = sitesData.map((item) => {
                if (item?.icon) {
                  delete item.icon;
                }
                return item;
              });
              taskData.sites = [...sites];
            }
          }
        }
      }
    }
    return taskData;
  });

  return autogenData;
};

export const loadTaskListFromDatabase = () => async (dispatch, getState) => {
  const scheduledEvents: Array<IMixlEvent> = [];
  dispatch(fetchingTaskList(true));
  try {
    const totalTasks: Array<IMixlTask> = [];
    //listArrayWorkTimes
    const mixlTasks = await fetchMixlTaskList();
    if (mixlTasks?.length) {
      console.log('loadTaskListFromDatabase: mixlTasks ', mixlTasks.length);
      totalTasks.push(...mixlTasks.filter((item) => item !== undefined));
    }
    const store = getState() as RootState;
    const { workTemplate = [], workingStartTime, workingEndTime } = store.businessData.user_details;
    console.log('loadTaskListFromDatabase: ', workTemplate, workingStartTime, workingEndTime);

    const workingHours = getWorkingHours(workingStartTime, workingEndTime);
    const fireToken = await getUserToken();

    const clickupCredential = store.businessData?.clickupCredential;
    if (clickupCredential) {
      const clickupTaskList = await fetchClickupTasks(fireToken);
      if (clickupTaskList.length > 0) {
        console.log('loadTaskListFromDatabase: clickupTaskList ', clickupTaskList.length);
        totalTasks.push(...clickupTaskList);
      }
    }
    const jiraCredential = store.businessData.jiraCredential;
    if (jiraCredential) {
      console.log('jiraCredential: ', jiraCredential);
      const { jiraTasks } = await GetSecuredData('mixer/get_jira_tasks', `${fireToken}`);
      if (jiraTasks?.length) {
        console.log('loadTaskListFromDatabase: jiraTasks ', jiraTasks.length);
        totalTasks.push(...jiraTasks);
      }
    }
    const notionCredential = store.businessData.notionCredential;
    if (notionCredential) {
      const { notionTasks } = await GetSecuredData('mixer/get_notion_tasks', `${fireToken}`);
      if (notionTasks?.length) {
        console.log('loadTaskListFromDatabase: notionTasks ', notionTasks.length);
        totalTasks.push(...notionTasks);
      }
    }
    dispatch(updateClickupTaskList(totalTasks));

    console.log('loadTaskListFromDatabase: total tasks ', totalTasks);

    const uid = firebaseAuth.currentUser?.uid;
    const eventList = await fetchScheduleToday(uid);
    if (!eventList.length) {
      console.log('loadTaskListFromDatabase: No events found today');
      const yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-LLLL-dd');
      const eventsYesterday = await fetchDaySchedule(uid, yesterday);
      if (eventsYesterday.length) {
        const incompleteEvents = eventsYesterday.filter((item) => {
          const taskStatus = item.taskStatus;
          return taskStatus === 'open' || taskStatus === 'in progress';
        });
        if (incompleteEvents.length) {
          console.log('loadTaskListFromDatabase: incompleteEvents from yesterday ', incompleteEvents);
          eventList.push(...incompleteEvents);
        }
      }
    }

    const eventsWithData = await getMixlEventsWithTaskData(eventList, totalTasks);
    await dispatch(maybeAddLearningBlock(workingHours, eventsWithData));

    console.log('loadTaskListFromDatabase: eventList ', eventList, eventsWithData);
    if (eventsWithData.length > 0) {
      scheduledEvents.push(...eventsWithData);
      const newTaskList = getOngoingTasks(totalTasks);
      // event discovery
      const { unScheduledTasks, scheduledTasks } = getUnscheduledTaskList(newTaskList, eventsWithData);
      console.log('loadTaskListFromDatabase: unScheduledTasks ', unScheduledTasks, scheduledTasks);
      if (unScheduledTasks.length) {
        const priorityTasks = sortByPriority(unScheduledTasks);
        const { newScheduledTasks: moreTasks } = await scheduleMixlTasks(
          priorityTasks,
          scheduledTasks,
          workTemplate,
          workingHours
        );
        console.log('loadTaskListFromDatabase: moreTasks ', moreTasks);
        const newScheduledEvents = convertTasksToEvents(moreTasks);
        scheduledEvents.push(...newScheduledEvents);
      }
    } else {
      console.log('loadTaskListFromDatabase: no task found. rescheduling now');
      // if there are no event schedule events
      const unscheduledTasks = sortByPriority(getOngoingTasks(totalTasks));
      console.log('loadTaskListFromDatabase: unscheduledTasks ', unscheduledTasks);
      const { newScheduledTasks: taskEvents } = await scheduleMixlTasks(
        unscheduledTasks,
        [],
        workTemplate,
        workingHours
      );
      const oldScheduledEvents = convertTasksToEvents(taskEvents);
      scheduledEvents.push(...oldScheduledEvents);
    }
  } catch (error) {
    console.log('Err: loadTaskListFromDatabase ', error);
  } finally {
    console.log('scheduledEvents=====', scheduledEvents);
    saveDaySchedule(scheduledEvents);
    dispatch(updateMixlCalendarEvents(scheduledEvents));
    dispatch(fetchingTaskList(false));
    dispatch(setIsScheduling(false));
  }

  return scheduledEvents;
};

export const updateMixlCalendarEvents = (events: Array<IMixlEvent>) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.UPDATE_MIXL_CALENDAR,
    events
  });
};

const maybeAddLearningBlock =
  (workingHours: IWorkingHours, eventsWithData: IMixlEvent[]) => async (dispatch, getState) => {
    const store = getState() as RootState;
    const totalTasks = store.mixlData.clickupTasks;
    const finalHour = isFinalHourAvailable(workingHours, eventsWithData);
    if (finalHour) {
      console.log('loadTaskListFromDatabase: adding quick todo and learing block ', eventsWithData);

      const todoStartTime = DateTime.fromJSDate(finalHour);
      const todoEndTime = todoStartTime.plus({ minutes: 30 });
      const todoTitle = 'Quick Todos';
      const todoTask = await dispatch(
        addQuickMixlTask(todoTitle, {
          startTimestamp: todoStartTime.toMillis(),
          endTimestamp: todoEndTime.toMillis(),
          eventName: todoTitle
        })
      );
      totalTasks.push(todoTask);
      const quickTodoEvent = convertTasksToEvents([todoTask]);
      await saveDaySchedule(quickTodoEvent);
      eventsWithData.push(...quickTodoEvent);

      const learningStartTime = DateTime.fromJSDate(finalHour).plus({ minutes: 30 });
      const learningEndTime = learningStartTime.plus({ minutes: 30 });
      const learningTitle = 'Learning';
      const learningTask = await dispatch(
        addQuickMixlTask(learningTitle, {
          startTimestamp: learningStartTime.toMillis(),
          endTimestamp: learningEndTime.toMillis(),
          eventName: learningTitle
        })
      );
      totalTasks.push(learningTask);
      const learningEvent = convertTasksToEvents([learningTask]);
      await saveDaySchedule(learningEvent);
      eventsWithData.push(...learningEvent);
    }
  };

function getOngoingTasks(totalTasks: IMixlTask[]) {
  const taskList = totalTasks.filter((item) => {
    const taskStatus = item?.status?.status ?? 'open';
    const taskStatusText = taskStatus.toLowerCase();
    if (taskStatusText === 'closed' || taskStatusText === 'done' || taskStatus === 'review') {
      return false;
    }
    return true;
  });

  return [...taskList];
}

async function updateSessionTimer(sessionId: string) {
  if (!sessionId) {
    return;
  }
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', sessionId);
  const snapshot = await getDoc(docRef);
  if (snapshot.exists()) {
    const sessionData = snapshot.data();
    // console.log(sessionData);
    const sessionLength = sessionData?.sessionLength;
    // check for negative values. e.g unhandled case of timer counting after session has ended
    const timeUsed = getTimerValue(sessionData);
    const timeCompleted = timeUsed <= sessionLength ? sessionLength - timeUsed : sessionLength;
    const breakTime = sessionData?.breakTime;

    await updateDoc(docRef, { timeCompleted });
    if (
      sessionLength - breakTime > 0 &&
      timeCompleted > (sessionLength - breakTime) / 2 &&
      !sessionData.notifHalfaway
    ) {
      const uid = firebaseAuth.currentUser?.uid;
      const flowSessionsRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', sessionId);
      const snapshotflowSessions = await getDoc(flowSessionsRef);
      if (snapshotflowSessions.exists()) {
        await updateDoc(flowSessionsRef, {
          notifHalfaway: true
        });
      }

      const timeEstimation =
        ((sessionLength ?? 0) - (breakTime ?? 0)) % (2 * 60) > 0
          ? (((sessionLength ?? 0) - (breakTime ?? 0)) / (2 * 60)).toFixed(1)
          : Math.floor(((sessionLength ?? 0) - (breakTime ?? 0)) / (2 * 60));
      postIframeMessage({
        type: 'milestone',
        task_id: '4',
        description: `left to achieve your goal. `,
        time_estimation: `${timeEstimation}m`,
        task: 'Halfway Done'
      });
    }
  }
}

export const setMixlScreen = (payload) => (dispatch) => {
  if (payload === 'break') {
    const quote = getQuoteBreak();
    dispatch({
      type: mixlActionTypes.SET_SESSION_BREAK_QUOTE,
      payload: quote
    });
  } else if (payload === 'warmup') {
    const quote = getQuoteWarmup();
    dispatch({
      type: mixlActionTypes.SET_SESSION_WARMUP_QUOTE,
      payload: quote
    });
    dispatch(handleUpdateSkipWarmup(false));
  }
  dispatch({
    type: mixlActionTypes.SET_MIXL_SCREEN,
    payload
  });
  if (isExtenstionSidebar()) {
    postIframeMessage({ type: 'mixlScreen', screen: payload });
  }
};

// todo: are we using it?
export const handleUpdateSkipWarmup = (skip) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { currentSessionId } = store.mixlData;
  const uid = firebaseAuth.currentUser?.uid;
  dispatch({
    type: mixlActionTypes.SET_SKIPPED_WARMUP,
    payload: skip
  });
  // localStorage.setItem('skipwarmup', skip);
  if (!currentSessionId) {
    return;
  }
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
  const snapshot = await getDoc(docRef);
  const sessionData = snapshot.data();

  if (sessionData) {
    await updateDoc(docRef, {
      skippedWarmup: skip
    });
  }
};

// todo: are we using it?
const getSession = async (sessionId) => {
  try {
    let lastSession = null;
    const uid = firebaseAuth.currentUser?.uid;
    const querySnapshot = await getDocs(
      query(collection(firebaseDB, 'glo-users', uid, 'flow_sessions'), where('sessionId', '==', sessionId), limit(1))
    );

    if (!querySnapshot.size) {
      return;
    }
    querySnapshot.forEach((doc) => {
      // Get the last document in the collection
      lastSession = doc.data();
    });
    return lastSession;
  } catch (error) {
    console.error('Error getting documents: ', error);
  }
};

// todo: are we using it?
const fetchPlaylistsData = async () => {
  const playlists = {};
  for (const station of MUSIC_STATIONS) {
    const dbRef = collection(firebaseDB, 'ai_music', 'place_v13', `${station.abrev}`);
    const refQuery = query(dbRef, orderBy('timestamp', 'asc'));
    const snapshot = await getDocs(refQuery);
    if (snapshot.size > 0) {
      playlists[station.abrev] = snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
    }
  }
  //this is just temporary because we may have updated the playlist it will take time to generate
  if (!playlists[MUSIC_STATIONS[0].abrev]) {
    const dbRef = collection(firebaseDB, 'ai_music', 'place_v11', 'playlist');
    const refQuery = query(dbRef, orderBy('timestamp', 'asc'));
    const snapshot = await getDocs(refQuery);
    if (snapshot.size > 0) {
      playlists[MUSIC_STATIONS[0].abrev] = snapshot.docs.map((doc) => doc.data());
    }
  }
  return playlists;
};

export const setMuted = (value) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.UPDATE_MAIN_VOLUME,
    payload: value
  });
};

export const updateCurrentVolume = (value) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.UPDATE_PLAYER_VOLUME,
    payload: value
  });
};

export const handleStartSession = () => async (dispatch) => {
  console.log('click start');
  //save current session data
  //dispatch(setUserBlockTime(Number(currentSession?.workDuration) * 60));

  //start session
  const token = await getUserToken();
  PostSecuredData('moderator/update_activity', { activity: 'start_focus_session' }, token);
  await dispatch(createSession(true));
  dispatch(setMixlScreen(mixlTabs.focus));
  await dispatch(startFocusSession());
  dispatch(setEndAudioPlayed(false));
};

export const addSplitSessionData = (sessions: ISession[]) => {
  console.log('ADD_SPLIT_SESSION_DATA');
  return {
    type: mixlActionTypes.ADD_SPLIT_SESSION_DATA,
    sessions
  };
};

export const setAiSessionsData = (payload) => {
  return {
    type: mixlActionTypes.SET_AI_SESSIONS_DATA,
    payload
  };
};

export const updateStations = (payload) => {
  return {
    type: mixlActionTypes.SET_MUSIC_STATIONS,
    payload
  };
};

export const setTimestampStartTimer = (payload) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { currentSessionId } = store.mixlData;
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
  await updateDoc(docRef, {
    startedTimeAt: payload
  });
};

export const updateMusicLength = (payload) => async (dispatch, getState) => {
  const uid = firebaseAuth.currentUser?.uid;
  const store: RootState = getState();
  const { currentSessionId, sessionLength, breakTime } = store.mixlData;
  const { userBlockedTime, addedTime } = payload;
  if (addedTime) {
    dispatch({
      type: mixlActionTypes.SET_SESSION_FULL_TIME,
      payload: sessionLength + addedTime
    });
  }

  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
  const snapshot = await getDoc(docRef);
  const sessionData = snapshot.data();

  if (sessionData && addedTime) {
    await updateDoc(docRef, {
      sessionLength: sessionLength + addedTime
    });
  }

  if (isCapacitor) {
    await MixlAudioPlayer.startSessionTimer({ sessionLength: sessionLength + addedTime });
  }

  if (userBlockedTime > 0) {
    console.log('remainingTime', userBlockedTime + addedTime);
    postIframeMessage({ remainingTime: userBlockedTime + addedTime, breakTime: breakTime, type: 'taskTime' });
  }
};

const getTimerValue = (sessionData, stopTimer) => {
  console.log('sessionDatasessionData', sessionData, stopTimer);
  if (stopTimer) {
    const remainingTime = sessionData?.remainingTime;
    return remainingTime;
  } else {
    if (sessionData?.startedTimeAt && sessionData.remainingTime) {
      const syncCurrentTime = sessionData.startedTimeAt; //timestamp
      const currentTime = Date.now();
      const currentDuration = sessionData.remainingTime;
      const differenceInMilliseconds = Math.abs(currentTime - syncCurrentTime);
      const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
      const newDuration = currentDuration - differenceInSeconds;
      return newDuration;
    }
    if (sessionData?.notifBreak && sessionData?.breakStartTime) {
      const syncCurrentTime = sessionData.breakStartTime;
      const currentTime = Date.now();
      const currentDuration = sessionData.breakTime;
      const differenceInMilliseconds = Math.abs(currentTime - syncCurrentTime);
      const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
      const newDuration = currentDuration - differenceInSeconds;
      return newDuration;
    }

    const syncCurrentTime = sessionData.syncCurrentTime; //timestamp
    const currentTime = Date.now();
    const currentDuration = sessionData.sessionLength;
    const differenceInMilliseconds = Math.abs(currentTime - syncCurrentTime);
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    const newDuration = currentDuration - differenceInSeconds;
    return newDuration;
  }
};

export const setSelectedProgressTab = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SELECTED_PROGRESS_TAB,
    payload
  });
};

export const setshowTrackedSitesModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SHOW_TRACKED_SITES_MODAL,
    payload
  });
};

export const setshowWorkHoursModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SHOW_WORK_HOURS_MODAL,
    payload
  });
};

export const setshowFocusDetailsModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SHOW_FOCUS_DETAILS_MODAL,
    payload
  });
};
export const setshowEndDetailsModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SHOW_END_DETAILS_MODAL,
    payload
  });
};

export const getUserSessionData = (userEmail) => async (dispatch, getState) => {
  const token = await getUserToken();
  const { user } = await PostSecuredData('authapi/get_user_session', { userEmail }, token);
  console.log('user===', user);
  const store = getState() as RootState;
  const selectedUser = store.mixlData?.selectedUser;
  if (selectedUser && selectedUser.email === userEmail) {
    const userData = { ...selectedUser, ...user };
    if (userData) {
      dispatch({
        type: mixlActionTypes.UPDATE_SELECTED_USER,
        payload: userData
      });
    }
  }
};
export const getTeamList = () => async (dispatch, getState) => {
  dispatch({
    type: mixlActionTypes.SET_FETCHING_TEAM_LIST,
    payload: true
  });
  const token = await getUserToken();
  const { team } = await GetSecuredData('authapi/get_teammates', token);

  const teamWithTasks = await Promise.all(
    team.map(async (user) => {
      const tasks = [];
      if (user?.uid) {
        const eventList = await fetchScheduleToday(user.uid);
        if (eventList.length) {
          for (const item of eventList) {
            const mixlEvent = convertMixlEvent(item, {});
            tasks.push(mixlEvent);
          }
        }
      }

      return { ...user, tasks };
    })
  );

  dispatch({
    type: mixlActionTypes.SET_TEAM_LIST,
    payload: teamWithTasks
  });
  const store = getState() as RootState;
  const selectedUser = store.mixlData?.selectedUser;
  if (selectedUser) {
    const userId = selectedUser?.uid;
    const userData = teamWithTasks.find((teamUser) => teamUser.uid === userId);
    if (userData) {
      dispatch({
        type: mixlActionTypes.UPDATE_SELECTED_USER,
        payload: userData
      });
    }
  }
  dispatch({
    type: mixlActionTypes.SET_FETCHING_TEAM_LIST,
    payload: false
  });
};

export const setSelectedUser = (userEmail) => async (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SELECTED_USER,
    payload: userEmail
  });
};

export const setShowIntegrationsModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SHOW_INTEGRATIONS_MODAL,
    payload
  });
};

export const setShowSlackMessageModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SHOW_SLACK_MESSAGE_MODAL,
    payload
  });
};

export const createQuickLearningList = (url) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { selectedTaskClickup, mixlCalendarEvents, showTaskDetailsModal } = store.mixlData;
  const itemId = Math.random().toString(36).substr(2, 9);
  const sortedEvents = [...mixlCalendarEvents].sort((a, b) => new Date(a.start) - new Date(b.start));
  // Find the first event with the type "learning"
  const firstLearningEvent = sortedEvents.find((event) => event.type === 'learning');
  console.log('firstLearningEvent==', firstLearningEvent);

  if (selectedTaskClickup && selectedTaskClickup.eventName === 'Learning') {
    const task = selectedTaskClickup;
    const updatedTask = await dispatch(
      editMixlTask({
        ...task,
        milestones: [...task.milestones, { id: itemId, title: url, completed: false }]
      })
    );
    dispatch(setSelectedTaskClickup(updatedTask));
    dispatch(setShowTaskDetailsModal(updatedTask));
  } else if (firstLearningEvent) {
    if (showTaskDetailsModal && firstLearningEvent?.task !== selectedTaskClickup) {
      await dispatch(setShowTaskDetailsModal());
      dispatch(setSelectedTaskClickup());
      await sleep(200);
    }
    const task = firstLearningEvent?.task;
    const prevChecklist = task?.milestones || [];
    const updatedTask = await dispatch(
      editMixlTask({
        ...task,
        milestones: [...prevChecklist, { id: itemId, title: url, completed: false }]
      })
    );
    dispatch(setSelectedTaskClickup(updatedTask));
    dispatch(setShowTaskDetailsModal(updatedTask));
  } else {
    dispatch({
      type: mixlActionTypes.SET_CREATING_READING_LIST,
      payload: true
    });
    dispatch(setShowTaskDetailsModal(null));
    const newChekListItem = { id: itemId, title: url, completed: false };
    const task = await dispatch(
      addQuickMixlTask(`Learning`, {
        startTimestamp: DateTime.now().toMillis(),
        endTimestamp: DateTime.now().plus({ hours: 1 }).toMillis(),
        eventName: 'Learning',
        milestones: [{ ...newChekListItem }]
      })
    );
    const newMixlEvent = convertTasksToEvents([task]);
    dispatch(updateMixlCalendarEvents([...mixlCalendarEvents, ...newMixlEvent]));
    await saveDaySchedule(newMixlEvent);
    dispatch(setSelectedTaskClickup(task));
    dispatch(setShowTaskDetailsModal(task));
    dispatch({
      type: mixlActionTypes.SET_CREATING_READING_LIST,
      payload: false
    });
  }
};
